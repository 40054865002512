import { Component, OnInit } from '@angular/core';
import { navItems } from './../../_nav';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, RoutesRecognized, NavigationEnd, ActivatedRoute, NavigationCancel } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { RootService } from '../../_services/root.service';
import { LocalService } from '../../_services/local.service';
import { Title } from '@angular/platform-browser';
import { UserNav } from '../../_models/nav.class';
import { ClientService } from '../../modules/clients/clients.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public navItems = [];
  public sidebarMinimized = true;
  public searchOn: boolean = false;
  public searchEvent = new Subject<string>();
  public searchSubcription: Subscription = null;
  public routerEventsSubcription: Subscription = null;
  public routesSubcription: Subscription = null;
  public navigating: boolean = false;
  public searching: boolean = false;
  public loadBarWidth: number = 0;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public user: any;
  public com: any;

  public listItems: any[] = [];
  constructor(private route: ActivatedRoute,
    private titleService: Title,
    public _authenticationServie: AuthenticationService,
    private _rootService: RootService,
    private _localService: LocalService,
    private router: Router,
    private clientsercice: ClientService) {
    this.changes = new MutationObserver((mutations) => {

      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }
  private intervalHandle: any = undefined;
  private twohour = 7200000;
  private timeOutMills = 7200000;// two hours
  ngOnInit() {
    this.handleRouterEvents();
    this.handleSearchEvents();
    this.handleTitles();
    this.user = this._authenticationServie.getUser();
    this.com = this._authenticationServie.getCom();

    this.navItems = this._authenticationServie.getNav();

    this.scheduleWarning(7200000);
  }
  scheduleWarning(mils) {
    let intervalHandle = setInterval(() => {
      // console.log(`workin everywhere ${new Date()}`)
      this.clientsercice.CheckClientBlock().subscribe((res: any) => {
        console.log('caled',res);

        if (res.IsWorning == true) {
          if (this.openPayentDueDialog == false) {
            this.openPayentDueDialog = true;
            clearInterval(intervalHandle);
          }
        }

        if (res.IsBlock == true) {
          this.router.navigate(['/locked-out']);
        }
      }, (err) => {

      });
    }, mils);
  }

  public openPayentDueDialog = false;
  ClosePayentDueDialog() {
    this.openPayentDueDialog = false;
    if (this.intervalHandle == undefined) {
      this.scheduleWarning(this.timeOutMills); // set for 2 hours
    }
  }

  public handleRouterEvents() {
    this.routerEventsSubcription = this.router.events.subscribe((_) => {
      if ((_ instanceof NavigationStart)) {
        this.navigating = true;
        setTimeout(() => {
          this.loadBarWidth += 10;
        }, 100);
      }
      if ((_ instanceof NavigationEnd)) {
        this.handleTitles();
        this.navigating = false;
      }
      if ((_ instanceof NavigationCancel)) {
        this.handleTitles();
        this.navigating = false;
      }
    });
  }
  public handleSearchEvents() {
    this.searchSubcription = this.searchEvent.pipe(
      debounceTime(400)).subscribe((x) => {
        this.search(x);
      });
  }
  public async search(value: string) {
    this.listItems = [];
    if (value.length > 2) {
      this.searching = true;
      try {
        this.listItems = await this._rootService.search(value).toPromise();
        console.log(this.listItems);
      } catch (error) {
        console.log(error);
      }
    }
  }
  public searchClicked(itemName) {
    let item = this.listItems.find(x => x.Name === itemName);
    if (item) {
      let navigateTo: string[] = [];
      if (item.Type == 1) {
        navigateTo.push('/health-facility/' + item.HFMISCode);
      } else if (item.Type == 2) {
        navigateTo.push('/profile/' + item.CNIC);
      } else if (item.Type == 3) {
        navigateTo.push('/order/' + item.Id);
      }
      this.router.navigate(navigateTo);
      this.searchOn = false;
    }

  }
  public handleTitles() {
    if (this.route.children.length > 0) {
      let child = this.route.children[0];
      this.routesSubcription = child.data.subscribe(data => {
        this.setTitle(data.title);
      });
    } else {
      this.routesSubcription = this.route.data.subscribe(data => {
        this.setTitle(data.title);
      });
    }
  }
  loadUserData() {
    /*  switch (this.user.HfmisCode.length) {
         case 1:
           this.userLevelName = 'Punjab';
           break;
         case 3:
           this._mainService.getDivisionsByCode(this.user.HfmisCode).subscribe((data) => {
             this.divisions = data;
             if (data && data.length > 0) {
               this.userLevelName = data[0].Name;
             }
           }, (err) => this.handleError(err));
           this.userLevelName = 'Punjab';
           break;
         case 6:
           this._mainService.getDistrictsByCode(this.user.HfmisCode).subscribe((data) => {
             if (data && data.length > 0) {
               this.userLevelName = data[0].Name;
             }
           }, (err) => this.handleError(err));
           this.userLevelName = 'Punjab';
           break;
         case 9:
           this._mainService.getTehsilsByCode(this.user.HfmisCode).subscribe((data) => {
             if (data && data.length > 0) {
               this.userLevelName = data[0].Name;
             }
           }, (err) => this.handleError(err));
           this.userLevelName = 'Punjab';
           break;
         case 19:
           this.userLevelName = 'Punjab';
           break;
   
         default:
           break;
       } */
  }
  public setTitle(title: string) {
    this.titleService.setTitle(title + ' - ERP');
  }
  public logout() {
    this._authenticationServie.logout();
  }



}
