import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from '../../_helpers/config.class';

import { AuthenticationService } from "../../_services/authentication.service";
import { DefineAccountViewModel } from "./accounts.class";
import { InstalmentVewModel } from "../store/store-model";




@Injectable()
export class AccountService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }

  public getVisaStatus() {
    return this.http.get(`${Config.getControllerUrl('Accounts', 'GetVisaStatus')}`);
  }
  public getPreHeads() {
    return this.http.get(`${Config.getControllerUrl('Accounts', 'GetPreHeads')}`);
  }
  public getHead1(e: number) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetHead1')}`, { Id: e });
  }
  public getHead2(e: number) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetHead2')}`, { Id: e });
  }
  public getAccNatuers() {
    return this.http.get(`${Config.getControllerUrl('Accounts', 'GetAccNatuers')}`);
  }
  public validateAccCode(code: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'ValidateAccCode')}`, { Code: code });
  }
  public ValidateAccCodeCheckDublication(code: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'ValidateAccCodeCheckDublication')}`, { Code: code });
  }
  public validateJVCode(code: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'ValidateJVCode')}`, { Code: code });
  }
  public saveJVList(obj: any) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Accounts', 'SaveJVList')}`, formData);
  }

  public saveAccount(obj: DefineAccountViewModel) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'SaveAccount')}`, obj);
  }
  public getAccountsList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searcTerm: string) {
    debugger;
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAccountsList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId ,Search: searcTerm});
  }
  public getJVList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetJVList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: searchTerm });
  }

  deleteJvList(id: any) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'DeleteJVList')}`, { Id: id });
  }

  public getDayBookList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string, fd?: Date, td?: Date) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetDayBookList')}`, {
      Skip: skip, PageSize: pageSize, Query: query, HfId: hfId,
      FromDate: fd, ToDate: td,
      GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: searchTerm
    });
  }
  public getAccountById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAccountById')}`, { Id: id });
  }

  public getAccountBySearch(type) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAccountBySearch')}`, { Type : type });
  }
  public getAccountBySearchCS(x: string, event: string) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetAccountBySearch')}`, { Code: x, Type: event });
  }

  public getJVById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetJVById')}`, { Id: id });
  }
  public saveDayBook(obj: InstalmentVewModel) {
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Accounts', 'SaveDayBook')}`, formData);
  }
  public GetJVListFromExcel(obj:any) {
    const formData = new FormData();
    formData.append('file', obj.excelFile);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetJVListFromExcel')}`, formData);
  }
  public GetDaybookListFromExcel(obj:any) {
    debugger;
    const formData = new FormData();
    formData.append('file', obj.excelFile);
    formData.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Accounts', 'GetDaybookListFromExcel')}`, formData);
  }
  ChangeAaccountPreferance(AccountId,AccountNature){
    debugger;
    return this.http.post(`${Config.getControllerUrl('Accounts', 'ChangeAaccountPreferance')}`, {Id:AccountId,categoryId:AccountNature});
  }
}
